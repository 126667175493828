.error-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  gap: 2em;

  header {
    h3 {
      font-size: 2.4em;
    }
    p {
      font-size: 2em;
    }
  }
  button {
    padding: 1em;
    border-radius: 4px;
    border: 1px solid black;
    cursor: pointer;
  }
}
