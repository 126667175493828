h1,
h2,
h3,
h4,
p {
  color: var(--fnt-reg-clr);
}

.App {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-clr);
}

.wrapper {
  max-width: 2000px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

a {
  text-decoration: none;
}

main {
  display: flex;
}
