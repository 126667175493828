.search__container {
  display: flex;
  gap: 0.5em;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.search__input-box {
  width: 100%;
  position: relative;
}
.search__input-box input {
  background-color: var(--bg-clr);
  padding: 0.5em;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border: 1px solid rgb(141, 141, 141);
  border-bottom: none;
}
.search__query-lists {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}
.search__query-lists p {
  color: var(--fnt-reg-clr);
  padding: 0.2em 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  background-color: var(--bg-clr);
  border-left: 1px solid rgb(141, 141, 141);
  border-right: 1px solid rgb(141, 141, 141);
}
.search__query-lists p:nth-last-of-type(1) {
  border-bottom: 1px solid rgb(141, 141, 141);
}
.search__query-lists p:hover {
  background-color: #fff;
}

@media (max-width: 1000px) {
  .search__container {
    display: flex;
    gap: 0.5em;
    align-items: center;
    width: 100%;
  }
  .search__input-box input {
    background-color: var(--bg-clr);
    padding: 0.5em;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border: 1px solid rgb(141, 141, 141);
    border-bottom: none;
  }
}/*# sourceMappingURL=searchInput.css.map */