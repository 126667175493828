body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --primary-clr: #27282e;
  --point-clr: #cf2929;
  --fnt-reg-clr: #2b2b2b;
  --fnt-white-clr: #e9e9e9;
  --bg-clr: #ececec;
}

body {
  min-width: 300px;
}

li {
  list-style: none;
}

@media (max-width: 900px) {
  body {
    font-size: 0.9rem;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 0.8rem;
  }
}
