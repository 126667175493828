.header {
  background-color: var(--primary-clr);
  z-index: 997;
}

nav {
  display: flex;
  margin: auto;
  height: 4em;
  justify-content: space-between;
  max-width: 2000px;

  > div {
    display: flex;
    gap: 1em;
    align-items: center;
    padding: 0 1em;
  }

  img {
    width: 3em;
  }

  ul {
    display: flex;
    gap: 1em;
    font-weight: bold;
  }

  a {
    display: flex;
    color: var(--fnt-white-clr);
  }

  a:hover,
  .active {
    color: var(--point-clr);
  }
}

.nav__search {
  max-width: 40rem;
  width: 100%;
  // padding-left: 1em;
}

@media (max-width: 500px) {
  .nav__search {
    display: none;
  }
}
