.toggler {
  display: none;
  position: fixed;
  top: 4em;
  left: 0;
  background-color: var(--primary-clr);
  width: 2.5em;
  height: 2.5em;
  padding: 0.2em;
  z-index: 1000;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
}
.toggler img {
  width: 100%;
  height: 100%;
}

.toggler-outside {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  transition-duration: 0.3s;
  transition-property: background-color, -webkit-backdrop-filter;
  transition-property: backdrop-filter, background-color;
  transition-property: backdrop-filter, background-color, -webkit-backdrop-filter;
  pointer-events: none;
}
.toggler-outside--active {
  display: block;
  background-color: rgba(61, 61, 61, 0.575);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  pointer-events: auto;
}

@media (max-width: 1000px) {
  .toggler {
    display: block;
  }
}/*# sourceMappingURL=menuToggler.css.map */