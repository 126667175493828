aside {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  flex: 1 1 40%;
  background-color: var(--primary-clr);
  z-index: 2;
  padding: 1em;
  gap: 2em;
  align-items: center;
  width: 100%;
}
aside section {
  width: 100%;
}
aside section > h3 {
  text-align: center;
  color: var(--fnt-white-clr);
}
aside .dot {
  width: 0.5em;
  height: 0.5em;
  background-color: rgb(228, 228, 230);
  margin: 0 auto;
  border-radius: 50%;
}
aside .scroll-to-top {
  position: sticky;
  top: 95%;
}
aside .scroll-to-top button {
  border: 1px solid #a3a3a3;
  padding: 0.5rem 2rem;
  color: #a3a3a3;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
}
aside .scroll-to-top button:hover {
  background-color: rgba(206, 206, 206, 0.267);
  color: #fff;
}

.search__section {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.search__history {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 400px;
  width: 100%;
  margin: auto;
}
.search__history__content {
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: space-between;
}
.search__history p {
  color: var(--fnt-white-clr);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
}
.search__button button {
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: var(--bg-clr);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search__button button:hover {
  outline: 2px solid var(--point-clr);
}
.search__button img {
  width: 1.3em;
  height: 1.3em;
}

.main aside section {
  display: flex;
  gap: 1.5em;
  flex-direction: column;
}
.main aside section button {
  max-width: 24rem;
  margin: auto;
  display: flex;
  width: 100%;
  padding: 1em 0.3em;
  justify-content: center;
  text-align: center;
  background-color: var(--bg-clr);
  border: none;
  border-radius: 0.5em;
  font-weight: bold;
  color: var(--fnt-reg-clr);
  cursor: pointer;
  position: relative;
  transition-property: border-radius, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  outline: none;
  box-shadow: 0px 0px 0px 0px rgba(80, 80, 80, 0.459);
}
.main aside section button:after {
  transition: 0.3s transform ease-in-out;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.507);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--bg-clr);
  top: 0;
  left: 100%;
  transform: translateX(100%);
}
.main aside section button:hover {
  color: var(--point-clr);
}
.main aside section button.activated {
  border-radius: 0.5em 0px 0px 0.5em;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.507);
  color: var(--point-clr);
}
.main aside section button.activated:after {
  transform: translateX(0%);
}

.lists {
  position: relative;
  width: 100%;
  padding: 0 2em;
  padding-bottom: 2em !important;
  z-index: 100;
  background-color: var(--bg-clr);
  min-height: 100vh;
}
.lists__header-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 2rem 0;
  width: 100%;
  z-index: 99;
  gap: 1em;
  box-shadow: 0px 10px 6px -6px rgba(20, 20, 20, 0.527);
  text-align: center;
}
.lists__header-box span {
  color: var(--point-clr);
  font-weight: bold;
}
.lists__container {
  display: flex;
  flex-wrap: wrap;
}
.lists__recommend {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 98;
  box-shadow: 0px 10px 6px -6px rgba(20, 20, 20, 0.527);
}
.lists__recommend--not-empty {
  height: 18em;
}
.lists__recommend__text-box {
  position: absolute;
  left: 2em;
  bottom: 2em;
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  z-index: 9;
}
.lists__recommend__text-box h3 {
  color: var(--fnt-dark-clr);
  font-size: 1.2em;
  text-shadow: 0px 0px 10px white;
}
.lists__recommend__text-box p {
  max-width: 16em;
  color: var(--fnt-white-clr);
  font-size: 1.4em;
  font-weight: bolder;
  text-shadow: 0px 0px 10px black;
}
.lists__recommend__buttons {
  display: flex;
  gap: 0.5em;
  margin-top: 1em;
}
.lists__recommend__buttons button {
  display: flex;
  padding: 0.5em 1em;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--primary-clr);
  border-radius: 4px;
  width: 6em;
  cursor: pointer;
  font-weight: bold;
  color: var(--bg-clr);
}
.lists__recommend__buttons button:hover {
  filter: brightness(120%);
}
.lists__recommend__buttons img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 1.6em;
  height: 1.6em;
}
.lists__recommend__box {
  max-width: 1000px;
  display: flex;
  position: relative;
  width: 100%;
}
.lists__recommend__img-box {
  position: absolute;
  right: 2em;
  top: 0;
  max-width: 500px;
  width: 100%;
  height: 100%;
}
.lists__recommend__img-box img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.lists__recommend__img-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: linear-gradient(to left, var(--bg-clr) 0% 3%, transparent 25% 75%, var(--bg-clr) 97% 100%);
}
.lists .border {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.lists .border > div {
  position: absolute;
  width: 2em;
  height: 2em;
  z-index: 99;
}
.lists .border > div::before, .lists .border > div::after {
  content: "";
  position: absolute;
  width: 2em;
  height: 2em;
  background-color: var(--primary-clr);
  z-index: 1;
}
.lists .border > div:nth-of-type(1) {
  left: 0;
  top: 0;
}
.lists .border > div:nth-of-type(1)::after {
  border-top-left-radius: 50%;
  background-color: var(--bg-clr);
  z-index: 3;
}
.lists .border > div:nth-child(2) {
  left: 0;
  bottom: 0;
}
.lists .border > div:nth-child(2)::after {
  border-bottom-left-radius: 50%;
  background-color: var(--bg-clr);
  z-index: 3;
}
.lists .border > div:nth-child(3) {
  top: 0;
  right: 0;
}
.lists .border > div:nth-child(3)::after {
  border-top-right-radius: 50%;
  background-color: var(--bg-clr);
  z-index: 3;
}
.lists .border > div:nth-child(4) {
  bottom: 0;
  right: 0;
}
.lists .border > div:nth-child(4)::after {
  border-bottom-right-radius: 50%;
  background-color: var(--bg-clr);
  z-index: 3;
}
.lists__content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.lists__item {
  display: flex;
  flex: 0 0 20%;
  padding-bottom: 30%;
  position: relative;
}
.lists__item img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  overflow: hidden;
}
.lists__item__text-box {
  position: absolute;
  opacity: 0;
  display: flex;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  text-align: center;
  gap: 0.2em;
  cursor: pointer;
  transition: 0.3s opacity;
}
.lists__item__text-box p {
  font-size: 0.9em;
  color: var(--fnt-white-clr);
  text-shadow: 0px 1px 5px black;
  font-weight: bold;
  pointer-events: none;
}
.lists__item__text-box p span {
  color: red;
  font-size: 1em;
}
.lists__item__text-box p:first-child {
  height: 2.6em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.lists__item:hover .lists__item__text-box {
  opacity: 1;
}
.lists__item:hover img {
  filter: brightness(30%);
}

@media (max-width: 1200px) {
  .lists__item {
    flex: 0 0 25%;
    padding-bottom: 45%;
  }
}
@media (max-width: 1000px) {
  main {
    flex-direction: column;
  }
  main aside {
    position: fixed;
    z-index: 999;
    left: 100%;
    top: 0;
    max-width: none;
    transition: 0.3s transform;
    overflow-y: auto;
    height: 100vh;
  }
  main aside.toggled {
    transform: translateX(-100%);
  }
  .main aside {
    padding: 4em 0;
  }
  .main aside button:after {
    display: none;
  }
  .main aside button.activated {
    border-radius: 0.5em !important;
    color: var(--point-clr);
  }
  .search aside {
    padding-top: 8rem;
  }
  .lists {
    padding: 0 1em;
  }
  .border {
    display: none;
  }
  .scroll-to-top {
    display: none;
  }
}
@media (max-width: 570px) {
  .lists__recommend__img-box {
    left: 0;
    right: 0;
  }
  .lists__recommend__img-box::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: linear-gradient(to left, var(--bg-clr) 0% 1%, transparent 20% 80%, var(--bg-clr) 99% 100%);
  }
  .lists__item {
    flex: 0 0 50%;
    padding-bottom: 70%;
  }
}/*# sourceMappingURL=main.css.map */