.loading {
  width: 100%;
  height: 18em;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    position: absolute;
    border: 0.8em solid transparent;
    border-color: rgb(31, 32, 31) rgba(70, 66, 66, 0.486) transparent
      transparent;
    border-radius: 50%;
    width: 4em;
    height: 4em;

    animation: 1.2s ani-loading infinite;

    &:nth-child(1) {
      animation-delay: -0.15s;
    }
    &:nth-child(2) {
      animation-delay: 0s;
    }
    &:nth-child(3) {
      animation-delay: 0.15s;
    }
  }
}

@keyframes ani-loading {
  100% {
    transform: rotate(-360deg);
  }
}
