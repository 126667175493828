.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  z-index: 1002;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  /* Chrome, Safari, Edge */
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.modal ::-webkit-scrollbar {
  width: 0px;
}
.modal__container {
  position: relative;
  display: flex;
  border-radius: 4px;
  background-color: #fff;
  gap: 1em;
  max-width: 60em;
  max-height: 40em;
  width: 100%;
  height: 100%;
  padding: 2em;
  overflow-y: auto;
}
.modal__container span {
  font-weight: bold;
}
.modal__front-poster {
  position: relative;
  flex: 1 1 40%;
  max-width: 24em;
  width: 100%;
  height: 100%;
  min-height: 26em;
}
.modal__front-poster img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.modal__header {
  padding-bottom: 1em;
  border-bottom: 1px solid rgb(197, 197, 197);
}
.modal__header h4 {
  font-size: 1.2em;
}
.modal__header__original {
  color: rgb(112, 112, 112);
}
.modal__info {
  min-height: 26em;
  flex: 1 1 10%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.modal__text-box {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.modal__genre {
  display: flex;
  align-items: center;
  gap: 0.3em;
}
.modal__genre div {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
}
.modal__genre div p {
  font-size: 0.9em;
  border-radius: 4px;
  padding: 0.2em 0.5em;
  background-color: var(--primary-clr);
  color: var(--bg-clr);
}
.modal__back-poster {
  width: 100%;
  height: 100%;
}
.modal .modal__close {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(240, 240, 240);
  box-shadow: 0px 0px 10px -3px black;
}
.modal .modal__close img {
  width: 1.5em;
  height: 1.5em;
}
.modal .modal__close:hover {
  background-color: rgb(231, 231, 231);
}
.modal__outside {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.801);
  cursor: pointer;
  z-index: -1;
}

@media (max-width: 680px) {
  .modal__container {
    padding: 1em;
    flex-direction: column;
    align-items: center;
  }
  .modal__front-poster {
    display: flex;
    justify-content: center;
    max-width: 18em;
    background-color: red;
  }
  .modal__info {
    overflow-y: visible;
    height: auto;
    min-height: auto;
  }
}/*# sourceMappingURL=movieModal.css.map */