.page-announce {
  display: flex;
  padding: 10em 1em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;

  &__text {
    display: flex;
    flex-direction: column;
    gap: 5em;
    padding: 2em;
    max-width: 40em;

    h3 {
      font-size: 3em;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
    li {
      font-size: 1.1em;
      list-style-type: disc;
      span {
        color: var(--point-clr);
        font-weight: bold;
      }
    }
  }

  img {
    flex: 0 1 30%;

    width: 22em;
    animation: 2s ani-updown ease-in-out infinite alternate;
  }

  @media (max-width: 900px) {
    padding: 4em 1em;
  }
  @media (max-width: 600px) {
    padding: 2em 1em;
  }
}

@keyframes ani-updown {
  100% {
    transform: translateY(-1rem);
  }
}
