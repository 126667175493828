.search {
  &__container {
    display: flex;
    gap: 0.5em;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  &__input-box {
    width: 100%;
    // max-width: 30em;
    position: relative;
    input {
      background-color: var(--bg-clr);
      padding: 0.5em;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      border: 1px solid rgb(141, 141, 141);
      border-bottom: none;
    }
  }
  &__query-lists {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    p {
      color: var(--fnt-reg-clr);
      padding: 0.2em 0.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      cursor: pointer;
      background-color: var(--bg-clr);
      border-left: 1px solid rgb(141, 141, 141);
      border-right: 1px solid rgb(141, 141, 141);
      &:nth-last-of-type(1) {
        border-bottom: 1px solid rgb(141, 141, 141);
      }

      &:hover {
        background-color: #fff;
      }
    }
  }
}

@media (max-width: 1000px) {
  .search {
    &__container {
      display: flex;
      gap: 0.5em;
      align-items: center;
      width: 100%;
    }
    &__input-box {
      input {
        background-color: var(--bg-clr);
        padding: 0.5em;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        border: 1px solid rgb(141, 141, 141);
        border-bottom: none;
      }
    }
  }
}
