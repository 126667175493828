.noposter {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(204, 204, 204);

  img {
    width: 50% !important;
    height: 50% !important;
    object-fit: contain !important;
    object-position: center;
  }
}
