.header {
  background-color: var(--primary-clr);
  z-index: 997;
}

nav {
  display: flex;
  margin: auto;
  height: 4em;
  justify-content: space-between;
  max-width: 2000px;
}
nav > div {
  display: flex;
  gap: 1em;
  align-items: center;
  padding: 0 1em;
}
nav img {
  width: 3em;
}
nav ul {
  display: flex;
  gap: 1em;
  font-weight: bold;
}
nav a {
  display: flex;
  color: var(--fnt-white-clr);
}
nav a:hover,
nav .active {
  color: var(--point-clr);
}

.nav__search {
  max-width: 40rem;
  width: 100%;
}

@media (max-width: 500px) {
  .nav__search {
    display: none;
  }
}/*# sourceMappingURL=navbar.css.map */