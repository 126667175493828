aside {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  flex: 1 1 40%;
  background-color: var(--primary-clr);
  z-index: 2;
  padding: 1em;
  gap: 2em;
  align-items: center;
  width: 100%;

  section {
    width: 100%;
    > h3 {
      text-align: center;
      color: var(--fnt-white-clr);
    }
  }

  .dot {
    width: 0.5em;
    height: 0.5em;
    background-color: rgb(228, 228, 230);
    margin: 0 auto;
    border-radius: 50%;
  }

  .scroll-to-top {
    position: sticky;
    top: 95%;
    button {
      border: 1px solid #a3a3a3;
      padding: 0.5rem 2rem;
      color: #a3a3a3;
      border-radius: 4px;
      cursor: pointer;
      background-color: transparent;
      &:hover {
        background-color: rgba(206, 206, 206, 0.267);
        color: #fff;
      }
    }
  }
}

.search {
  &__section {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
  &__history {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 400px;
    width: 100%;
    margin: auto;

    &__content {
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: space-between;
    }
    p {
      color: var(--fnt-white-clr);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      cursor: pointer;
    }
  }

  &__button {
    button {
      flex-shrink: 0;
      width: 2em;
      height: 2em;
      border-radius: 4px;
      border: none;
      outline: none;
      background-color: var(--bg-clr);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        outline: 2px solid var(--point-clr);
      }
    }
    img {
      width: 1.3em;
      height: 1.3em;
    }
  }
}

.main {
  aside {
    section {
      display: flex;
      gap: 1.5em;
      flex-direction: column;

      button {
        max-width: 24rem;
        margin: auto;
        display: flex;
        width: 100%;
        padding: 1em 0.3em;
        justify-content: center;
        text-align: center;
        background-color: var(--bg-clr);
        border: none;
        border-radius: 0.5em;
        font-weight: bold;
        color: var(--fnt-reg-clr);
        cursor: pointer;
        position: relative;
        transition-property: border-radius, box-shadow;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        outline: none;
        box-shadow: 0px 0px 0px 0px rgba(80, 80, 80, 0.459);
        // height: 3em;
        &:after {
          transition: 0.3s transform ease-in-out;
          box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.507);
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background-color: var(--bg-clr);
          top: 0;
          left: 100%;
          transform: translateX(100%);
        }
        &:hover {
          color: var(--point-clr);
        }
        &.activated {
          border-radius: 0.5em 0px 0px 0.5em;
          box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.507);
          color: var(--point-clr);
          &:after {
            transform: translateX(0%);
          }
        }
      }
    }
  }
}

.lists {
  position: relative;
  width: 100%;
  padding: 0 2em;
  padding-bottom: 2em !important;
  z-index: 100;
  background-color: var(--bg-clr);
  min-height: 100vh;

  &__header-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 2rem 0;
    width: 100%;
    z-index: 99;
    gap: 1em;
    box-shadow: 0px 10px 6px -6px rgba(20, 20, 20, 0.527);
    text-align: center;

    span {
      color: var(--point-clr);
      font-weight: bold;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__recommend {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 98;
    box-shadow: 0px 10px 6px -6px rgba(20, 20, 20, 0.527);

    // when fetching the image, this box will hold the height without the fetchced image
    &--not-empty {
      height: 18em;
    }

    &__text-box {
      position: absolute;
      left: 2em;
      bottom: 2em;
      display: flex;
      gap: 0.5em;
      flex-direction: column;
      z-index: 9;

      h3 {
        color: var(--fnt-dark-clr);
        font-size: 1.2em;
        text-shadow: 0px 0px 10px white;
      }
      p {
        max-width: 16em;
        color: var(--fnt-white-clr);
        font-size: 1.4em;
        font-weight: bolder;
        text-shadow: 0px 0px 10px black;
      }
    }

    &__buttons {
      display: flex;
      gap: 0.5em;
      margin-top: 1em;

      button {
        display: flex;
        padding: 0.5em 1em;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: var(--primary-clr);
        border-radius: 4px;
        width: 6em;
        cursor: pointer;
        font-weight: bold;
        color: var(--bg-clr);
        &:hover {
          filter: brightness(120%);
        }
      }
      img {
        object-fit: cover;
        object-position: center;
        width: 1.6em;
        height: 1.6em;
      }
    }

    &__box {
      max-width: 1000px;
      display: flex;
      position: relative;
      width: 100%;
    }

    &__img-box {
      position: absolute;
      right: 2em;
      top: 0;
      max-width: 500px;
      width: 100%;
      height: 100%;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9;

        background: linear-gradient(
          to left,
          var(--bg-clr) 0% 3%,
          transparent 25% 75%,
          var(--bg-clr) 97% 100%
        );
      }
    }
  }

  .border {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    > div {
      position: absolute;
      width: 2em;
      height: 2em;
      z-index: 99;
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 2em;
        height: 2em;
        background-color: var(--primary-clr);
        z-index: 1;
      }
      &:nth-of-type(1) {
        left: 0;
        top: 0;
        &::after {
          border-top-left-radius: 50%;
          background-color: var(--bg-clr);
          z-index: 3;
        }
      }
      &:nth-child(2) {
        left: 0;
        bottom: 0;
        &::after {
          border-bottom-left-radius: 50%;
          background-color: var(--bg-clr);
          z-index: 3;
        }
      }
      &:nth-child(3) {
        top: 0;
        right: 0;
        &::after {
          border-top-right-radius: 50%;
          background-color: var(--bg-clr);
          z-index: 3;
        }
      }
      &:nth-child(4) {
        bottom: 0;
        right: 0;
        &::after {
          border-bottom-right-radius: 50%;
          background-color: var(--bg-clr);
          z-index: 3;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    display: flex;
    flex: 0 0 20%;
    padding-bottom: 30%;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
    }

    &__text-box {
      position: absolute;
      opacity: 0;
      display: flex;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1em;
      text-align: center;
      gap: 0.2em;
      cursor: pointer;
      transition: 0.3s opacity;

      p {
        font-size: 0.9em;
        color: var(--fnt-white-clr);
        text-shadow: 0px 1px 5px black;
        font-weight: bold;
        pointer-events: none;

        span {
          color: red;
          font-size: 1em;
        }

        &:first-child {
          height: 2.6em;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
        }
      }
    }
    &:hover {
      .lists__item__text-box {
        opacity: 1;
      }
      img {
        filter: brightness(30%);
      }
    }
  }
}

@media (max-width: 1200px) {
  .lists {
    &__item {
      flex: 0 0 25%;
      padding-bottom: 45%;
    }
  }
}

@media (max-width: 1000px) {
  main {
    flex-direction: column;
    aside {
      position: fixed;
      z-index: 999;
      left: 100%;
      top: 0;
      max-width: none;
      transition: 0.3s transform;
      overflow-y: auto;
      height: 100vh;
      &.toggled {
        transform: translateX(-100%);
      }
    }
  }
  .main {
    aside {
      padding: 4em 0;
      button {
        &:after {
          display: none;
        }
        &.activated {
          border-radius: 0.5em !important;
          color: var(--point-clr);
        }
      }
    }
  }
  .search {
    aside {
      padding-top: 8rem;
    }
  }

  .lists {
    padding: 0 1em;
  }

  .border {
    display: none;
  }
  .scroll-to-top {
    display: none;
  }
}

@media (max-width: 570px) {
  .lists {
    &__recommend {
      &__img-box {
        left: 0;
        right: 0;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 9;

          background: linear-gradient(
            to left,
            var(--bg-clr) 0% 1%,
            transparent 20% 80%,
            var(--bg-clr) 99% 100%
          );
        }
      }
    }

    &__item {
      flex: 0 0 50%;
      padding-bottom: 70%;
    }
  }
}
